import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import './Breadcrumbs.scss';
import { useStaticQuery, graphql } from "gatsby";
import { Expresses_Page_Url, Research_Page_Url } from "@Components/common/site/constants";
const Breadcrumbs = (props) => {
  const data = useStaticQuery(graphql`
  query{

      glstrapi {
        allMenus(publicationState: LIVE) {
          Alias
          URL
          Label
          Main_Parent {
            Alias
            URL
          }
          Sub_Parent {
            Alias
            URL
          }
          Link_Type
        }


          pages {
              Alias
              Pagename
            }

        }
  

 

  }
`);
  const PageURL = data.glstrapi.allMenus.filter(item => item?.Alias === props?.alias).pop();


  var parent = ""
  var url = ""

  if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent == null)) {

    const Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();
    parent = Parent?.Pagename
    url = PageURL?.Main_Parent?.URL

  }

  else if ((PageURL?.Main_Parent != null) && (PageURL?.Sub_Parent != null)) {

    const Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Main_Parent.Alias).pop();

    const Sub_Parent = data.glstrapi.pages?.filter(item => item?.Alias === PageURL.Sub_Parent.Alias).pop();
    parent = Parent?.Pagename
    var sub_parent = Sub_Parent?.Pagename
    url = PageURL?.Main_Parent?.URL
    var sub_url = PageURL?.Sub_Parent?.URL
  }
  return (
    <div className="breadcrumb-wrap">
      <Container>
        <Row>
          <Col md='12'>
            <Breadcrumb>
              {props.Page !== 'Home' &&

                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              }
              {PageURL?.Main_Parent !== null &&
                <Breadcrumb.Item href={`/${url}`}>{parent}</Breadcrumb.Item>
              }
              {props.type === "details-page" &&
                <Breadcrumb.Item href={`/${url}/${PageURL?.URL}`}>{PageURL?.Label}</Breadcrumb.Item>
              }
              {props.typenew === "details-page-express" &&
                <Breadcrumb.Item href={`${Expresses_Page_Url.alias}`}>{Expresses_Page_Url?.name}</Breadcrumb.Item>
              }
               {/* {props.typenew === "details-page-research" &&
                <Breadcrumb.Item href={`${Research_Page_Url.alias}`}>{Research_Page_Url?.name}</Breadcrumb.Item>
              } */}
              {PageURL?.Sub_Parent !== null &&
                <Breadcrumb.Item href={`/${url}/${sub_url}`}>{sub_parent}</Breadcrumb.Item>
              }
              <Breadcrumb.Item active>{props.Page}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Breadcrumbs
