const generateFiltersfromPageData = (free_text_search, departmentVal, searchtypeVal, statusVal, statusenableDefaultVal, areaVal, bedVal, minpriceVal, maxpriceVal, buildingval, officeVal, energyVal, agreedVal, mustincludeVal, minsizeVal, maxsizeVal) => {
    // lets from the filters 
    // here the search login lives

    function removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    var newagreedVal = ''

    if (typeof window !== "undefined" && window) {
        newagreedVal = document?.getElementById("sale_agreed_hidden") ? document.getElementById("sale_agreed_hidden").value : ''
    }

    // console.log("agreedCheckVal", statusenableDefaultVal, agreedVal, $(".sale_agreed_hidden").val())

    if (newagreedVal != null && newagreedVal == "unchecked") {
        removeA(agreedVal, "sale agreed")
        removeA(agreedVal, "let agreed")
    }

    var myfilter = []
    //facet filters
    if (departmentVal) {
        myfilter.push("(department:" + departmentVal + ")")
    }
    if (searchtypeVal) {
        myfilter.push("(search_type:" + searchtypeVal + ")")
    }

    // mustinclude
    if ((Array.isArray(agreedVal))) {
        myfilter.push('(status:"' + (agreedVal).join('" OR status:"') + '")')
    }

    // more filter
    if ((Array.isArray(mustincludeVal))) {
        myfilter.push('(mustInclude:"' + (mustincludeVal).join('" OR mustInclude:"') + '")')
    }

    
    var area_name_length = areaVal.length
    var area_name = officeVal ? '' : area_name_length == 1 ? areaVal.toString() : ''
    if(free_text_search && free_text_search.length > 0) {
        area_name = area_name
    }
    else {
        area_name = area_name
    }

    if (areaVal && (areaVal.toString() != 'ireland') && (Array.isArray(areaVal)) && (area_name_length > 1 || area_name == true)) {
        myfilter.push('(search_areaslist:"' + (areaVal).join('" OR search_areaslist:"') + '")')
    }
    // if(areaVal && areaVal !== "london"){
    //     myfilter.push("(area:"+areaVal+" OR post_code:"+areaVal+" OR display_address:"+areaVal+")")
    // }
    if (buildingval) {
        myfilter.push("(building:" + buildingval + ")")
    }
    if(officeVal) {
        myfilter.push("(officeIds:" + officeVal + ")")
    }
    //numberic filters
    if (bedVal && bedVal > 0) {
        myfilter.push("(bedroom >= " + bedVal + ")")
    }
    if(bedVal === 0){
        myfilter.push("(bedroom <= " + bedVal + ")")
    }

    if (energyVal && energyVal > 0) {
        myfilter.push("(energyRatingValue >= " + energyVal + ")")
    }
    if ((minpriceVal && minpriceVal > 0) && (maxpriceVal && maxpriceVal > 0)) {
        myfilter.push("(price >= " + minpriceVal + " AND price <= " + maxpriceVal + ")")
    } else if (maxpriceVal && maxpriceVal > 0) {
        myfilter.push("(price <= " + maxpriceVal + ")")
    } else if (minpriceVal && minpriceVal > 0) {
        myfilter.push("(price >= " + minpriceVal + ")")
    }
    if ((minsizeVal && minsizeVal > 0) && (maxsizeVal && maxsizeVal > 0)) {
        myfilter.push("(floorarea_min >= " + minsizeVal + " AND floorarea_min <= " + maxsizeVal + ")")
    } else if (maxsizeVal && maxsizeVal > 0) {
        myfilter.push("(floorarea_min <= " + maxsizeVal + ")")
    } else if (minsizeVal && minsizeVal > 0) {
        myfilter.push("(floorarea_min >= " + minsizeVal + ")")
    }

    // if(pageVal){
    //     myfilter.push("(page: "+(pageVal)+")")
    // }
    return myfilter.join(' AND ')
}

export default generateFiltersfromPageData
