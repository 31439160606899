import { removeSpecialChar } from "../common/utils"
// const {removeSpecialChar } = require("@starberry/gatsby-theme-utils/Common/Utils")

const parseResultsUrl = (location) => {
    //split the URL
    if (location) {
        var pathUri_main = ""
        let departmentVal = ""
        let searchtypeVal = ""
        let statusVal = ""
        let statusenableDefaultVal = ""
        let areaVal = ""
        let bedVal = ""
        let minpriceVal = ""
        let maxpriceVal = ""
        let minsizeVal = ""
        let maxsizeVal = ""
        let pageVal = "1"
        let buildingval = ""
        let sortVal = ""
        let layoutVal = ""
        let energyVal = ""
        let agreedVal = ""
        let mustincludeVal = ""
        let officeVal = ""
        let includeStatus = [];

        let salespage = location.pathname.indexOf("/property/for-sale") > -1
        let commercialsalespage = location.pathname.indexOf("/commercial-property/for-sale") > -1
        let salesmappage = location.pathname.indexOf("/property-map/for-sale") > -1
        let commercialsalesmappage = location.pathname.indexOf("/commercial-property-map/for-sale") > -1
        let lettingspage = location.pathname.indexOf("/property/to-rent") > -1
        let commerciallettingspage = location.pathname.indexOf("/commercial-property/to-rent") > -1
        let lettingsmappage = location.pathname.indexOf("/property-map/to-rent") > -1
        let commerciallettingsmappage = location.pathname.indexOf("/commercial-property-map/to-rent") > -1

        let newhomesalespage = location.pathname.indexOf("/new-homes-for-sale") > -1
        let newhomesalesmappage = location.pathname.indexOf("/new-homes-map-for-sale") > -1

        let auctionsalespage = location.pathname.indexOf("/auction-property/for-sale") > -1
        let auctionsalesmappage = location.pathname.indexOf("/auction-property-map/for-sale") > -1

        let pastaucctionpage = location.pathname.indexOf("/services/property-auctions/past-auctions") > -1
        let upcomingauctionpage = location.pathname.indexOf("/services/property-auctions/upcoming-auctions") > -1

        if (salespage === true || commercialsalespage === true) {
            if (salespage === true) {
                pathUri_main = location.pathname.split("/property/for-sale")
                departmentVal = "residential"
            }
            else if (commercialsalespage === true) {
                pathUri_main = location.pathname.split("/commercial-property/for-sale")
                departmentVal = "commercial"
            }
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = "sale agreed"
            includeStatus = [statusVal, statusenableDefaultVal]
            layoutVal = ""
        }
        else if (lettingspage === true || commerciallettingspage === true) {
            if (lettingspage === true) {
                pathUri_main = location.pathname.split("/property/to-rent")
                departmentVal = "residential"
            }
            else if (commerciallettingspage === true) {
                pathUri_main = location.pathname.split("/commercial-property/to-rent")
                departmentVal = "commercial"
            }
            searchtypeVal = "lettings"
            statusVal = "For Rent"
            statusenableDefaultVal = "let agreed"
            includeStatus = [statusVal, statusenableDefaultVal]
            layoutVal = ""
        }
        else if (salesmappage === true || commercialsalesmappage === true) {
            if (salesmappage === true) {
                pathUri_main = location.pathname.split("/property-map/for-sale")
                departmentVal = "residential"
            }
            else if (commercialsalesmappage === true) {
                pathUri_main = location.pathname.split("/commercial-property-map/for-sale")
                departmentVal = "commercial"
            }
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = "sale agreed"
            includeStatus = [statusVal, statusenableDefaultVal]
            layoutVal = "map"
        }
        else if (lettingsmappage === true || commerciallettingsmappage === true) {
            if (lettingsmappage === true) {
                pathUri_main = location.pathname.split("/property-map/to-rent")
                departmentVal = "residential"
            }
            else if (commerciallettingsmappage === true) {
                pathUri_main = location.pathname.split("/commercial-property-map/to-rent")
                departmentVal = "commercial"
            }
            searchtypeVal = "lettings"
            statusVal = "For Rent"
            statusenableDefaultVal = "let agreed"
            includeStatus = [statusVal, statusenableDefaultVal]
            layoutVal = "map"
        }
        else if (newhomesalespage === true) {
            pathUri_main = location.pathname.split("/new-homes-for-sale")
            departmentVal = "newdevelopments"
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = ""
            includeStatus = [statusVal]
            layoutVal = ""

        }
        else if (newhomesalesmappage === true) {
            pathUri_main = location.pathname.split("/new-homes-map-for-sale")
            departmentVal = "newdevelopments"
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = ""
            includeStatus = [statusVal]
            layoutVal = "map"

        }
        else if (auctionsalespage === true) {
            pathUri_main = location.pathname.split("/auction-property/for-sale")
            departmentVal = "auction_residential"
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = ""
            includeStatus = [statusVal]
            layoutVal = ""

        }
        else if (pastaucctionpage === true) {
            pathUri_main = location.pathname.split("/services/property-auctions/past-auctions")
            departmentVal = "auction_residential"
            searchtypeVal = "sales"
            statusVal = "Sold"
            statusenableDefaultVal = "PostPoned"
            includeStatus = ["Sold", "PostPoned", "Withdrawn"]
            layoutVal = ""

        }
        else if (upcomingauctionpage === true) {
            pathUri_main = location.pathname.split("/services/property-auctions/upcoming-auctions")
            departmentVal = "auction_residential"
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = ""
            includeStatus = [statusVal]
            layoutVal = ""

        }
        else if (auctionsalesmappage === true) {
            pathUri_main = location.pathname.split("/auction-property-map/for-sale")
            departmentVal = "auction_residential"
            searchtypeVal = "sales"
            statusVal = "For Sale"
            statusenableDefaultVal = ""
            includeStatus = [statusVal]
            layoutVal = "map"

        }

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")
            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Area
                // if (pathUri[vi].indexOf("in-") >= 0) {
                //     areaVal = removeSpecialChar(pathUri[vi].replace("in-", ""), " ")
                // }
                if (pathUri[vi].indexOf("in-") >= 0) {
                    var areaValArrfilt = pathUri[vi].replace("in-", "")
                    areaVal = areaValArrfilt.split("-and-")
                }

                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                }
                if (pathUri[vi].indexOf("studio") >= 0) {
                    bedVal = 0
                }

                // Property type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    buildingval = pathUri[vi].replace("type-", "")
                }

                // Office type
                if (pathUri[vi].indexOf("dng-office-") >= 0) {
                    officeVal = pathUri[vi].replace("dng-office-", "")
                }

                // Energy rating type
                if (pathUri[vi].indexOf("-rating") >= 0) {
                    energyVal = pathUri[vi].replace("-rating", "")
                }

                // Agreed value
                if (pathUri[vi].indexOf("includes-") >= 0) {
                    let statusFilt1 = pathUri[vi].split("includes-");
                    includeStatus = [statusVal]
                    if (statusFilt1[1]) {
                        let statusFilt4 = statusFilt1[1].split("-and-")
                        if (statusFilt4.length > 0) {
                            for (let v = 0; v <= statusFilt4.length; v++) {
                                if (typeof statusFilt4[v] !== "undefined") {
                                    includeStatus.push(statusFilt4[v].replace(/-/g, " "))
                                }
                            }
                        }
                    }
                    agreedVal = pathUri[vi].replace("includes-", "")
                }

                // More filter


                if (pathUri[vi].indexOf("with-") >= 0) {
                    var mustincludeArrfilt = pathUri[vi].replace("with-", "")
                    mustincludeVal = mustincludeArrfilt.split("-and-")
                }

                // More filter

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("above-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("above-")
                    if (priceFilt1[1]) {
                        minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                        maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                        let priceFilt4 = priceFilt3[1].split("-and-")
                        minpriceVal = priceFilt4[0]
                        maxpriceVal = priceFilt4[1]
                    }
                }

                // Size

                if (pathUri[vi].indexOf("size-") >= 0) {
                    let sizeFilt1 = pathUri[vi].split("over-")
                    if (sizeFilt1[1]) {
                        minsizeVal = sizeFilt1[1]
                    }
                    let sizeFilt2 = pathUri[vi].split("below-")
                    if (sizeFilt2[1]) {
                        maxsizeVal = sizeFilt2[1]
                    }
                    let sizeFilt3 = pathUri[vi].split("-from-")
                    if (sizeFilt3[1]) {
                        let sizeFilt4 = sizeFilt3[1].split("-to-")
                        minsizeVal = sizeFilt4[0]
                        maxsizeVal = sizeFilt4[1]
                    }

                    //sortVal = index_name + "_size_asc"
                }
                
                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    let sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if (sortVal_filt === "") {
                        sortVal = process.env.GATSBY_ALGOLIA_INDEX_NAME;
                    }

                    if (sortVal_filt === "price-asc") {
                        sortVal = process.env.GATSBY_ALGOLIA_INDEX_NAME + "_price_asc"
                    }

                    if (sortVal_filt === "price-desc") {
                        sortVal = process.env.GATSBY_ALGOLIA_INDEX_NAME + "_price_desc"
                    }
                }
                

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }

                if (pathUri[vi].indexOf("property-map") >= 0) {
                    layoutVal = "map"
                }
            }
        }
        var mydatarr = []
        mydatarr['departmentVal'] = departmentVal
        mydatarr['searchtypeVal'] = searchtypeVal
        mydatarr['statusVal'] = statusVal
        mydatarr['statusenableDefaultVal'] = statusenableDefaultVal
        mydatarr['areaVal'] = areaVal
        mydatarr['bedVal'] = bedVal
        mydatarr['minpriceVal'] = minpriceVal
        mydatarr['maxpriceVal'] = maxpriceVal
        mydatarr['minsizeVal'] = minsizeVal
        mydatarr['maxsizeVal'] = maxsizeVal
        mydatarr['pageVal'] = pageVal
        mydatarr['buildingval'] = buildingval
        mydatarr['sortVal'] = sortVal
        mydatarr['layoutVal'] = layoutVal
        mydatarr['energyVal'] = energyVal
        mydatarr['agreedVal'] = includeStatus
        mydatarr['officeVal'] = officeVal
        mydatarr['mustincludeVal'] = mustincludeVal
        return mydatarr
    }
    return false
}

export default parseResultsUrl
